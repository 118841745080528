import React, { memo } from 'react';
import CookieConsent from 'react-cookie-consent';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Typography, { TypographyProps } from 'components/atoms/Typography';

import { StyledCookies } from './Cookies.styles';

import { CookiesProps } from './models.d';

const typographyProps: TypographyProps = { fontWeight: 500, size: 16, color: 'white' };

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Typography {...typographyProps}>{children}</Typography>,
    [INLINES.ENTRY_HYPERLINK]: ({ data }, node) => (
      <Link to={data.target.slug} ariaLabel={node} typography={typographyProps}>
        {node}
      </Link>
    ),
  },
};

const Cookies = ({ text, allowButton, declineButton }: CookiesProps) => (
  <StyledCookies>
    <CookieConsent
      data-testid="cookies"
      disableStyles
      enableDeclineButton
      flipButtons
      ButtonComponent={memo((e) => (
        <Button label={e.children} {...e} variant="quinary" />
      ))}
      buttonText={allowButton.label}
      declineButtonText={declineButton.label}
      ariaAcceptLabel={allowButton.ariaLabel}
      ariaDeclineLabel={declineButton.ariaLabel}
      containerClasses="cookies"
      contentClasses="cookies__content"
      buttonWrapperClasses="cookies__buttons-wrapper"
      buttonClasses="cookies__button cookies__button--allow"
      declineButtonClasses="cookies__button cookies__button--decline"
    >
      {renderRichText(text, options)}
    </CookieConsent>
  </StyledCookies>
);

export default Cookies;
