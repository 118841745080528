import styled from 'styled-components';
import { breakpoint } from 'styles';

import { StyledTypography } from 'components/atoms/Typography';

export const StyledCookies = styled.div`
  .cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-top-modal);
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    padding: var(--gap-xxxxxs) var(--gap-lg);
    background: var(--c-gray-800);

    ${breakpoint.lg} {
      flex-direction: row;
    }

    &__content {
      margin: var(--gap-xxs) var(--gap-xxxs) var(--gap-xxs) 0;
      text-align: center;

      ${breakpoint.lg} {
        text-align: left;
      }

      ${StyledTypography} {
        display: inline;

        a {
          ${StyledTypography} {
            text-decoration: underline;
          }
        }
      }
    }

    &__buttons-wrapper {
      display: flex;
      gap: var(--gap-xxxs);
      justify-content: center;
      align-items: center;
      width: 100%;

      ${breakpoint.lg} {
        justify-content: flex-start;
        width: unset;
      }

      &__button {
        a:active {
          pointer-events: none;
        }
      }
    }
  }
`;
